.container {
  position: relative;
}

.input-route {
  border-radius: 60px;
  margin: auto;
  width: 70%;
  border: 40px solid white;
  padding: 50px;
  background-color: #04142c;
  align-items: center;
  justify-content: center;
}

[title="Origen"] {
    color: white !important;
}

[title="Destino"] {
    color: white !important;
}

[title="Icon"] {
    color: white !important;
}
