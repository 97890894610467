
  .valientaLogo{
    background-image: url('../SideNav/valienta_logo.png');
    background-size: 100% 100% !important;
    width: 100%;
    height: 100%;
  }
  .valientaLogoMain{
    background-image: url('./valientaMainLogo.png');
    background-size: 100% 100% !important;
    width: 100%;
    height: 100%;
  }

