* {
  font-family: Muli;
}

.logo {
  height: 100px;
  margin: 16px;
  background-image: url('./valienta_logo.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.SideNav-sider {
  min-height: 100vh;
  max-width: 18% !important;
  min-width: 18% !important;
  font-size: 2em;
}

.ant-menu-item {
  height: 4em !important;
  padding-top: 10px !important;
  font-size: 1.2em !important;
}
.component-icons{
  font-size: 2em;
  margin-top: 1em;
}
.component-title{
  text-align: center;
  font-weight: 900;
  font-size: 2em;
}
